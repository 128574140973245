import * as React from "react"
import { graphql } from "gatsby"
// @ts-ignore
import Layout from "../components/layout"
// @ts-ignore
import SEO from "../components/seo"
import Img from "gatsby-image"
import styled from "styled-components"
import { colours } from "../global/colours"
import { Button } from "../components/button"

const PortfolioPage = ({
  data: {
    airtable: {
      data: { Name, Description, Services, Features, Main_Image, Link},
    },
  },
}: Props) => {
  return (
    <Layout showBlob={false}>
      <SEO title="Portfolio" />
      <Banner>
        <Img fluid={Main_Image.localFiles[0].childImageSharp.fluid} />
      </Banner>
      <Content>
        <a href={Link}><h1>{Name}</h1></a>
        <div
          dangerouslySetInnerHTML={{
            __html: Description.childMarkdownRemark.html,
          }}
        />
        <Divider />
        <Columns>
          <div>
            <h3>Services</h3>
            {Services.split("\n").map((service, i) => (
              <p key={i}>{service}</p>
            ))}
          </div>
          <div>
            <h3>Features</h3>
            {Features.split("\n").map((feature, i) => (
              <p key={i}>{feature}</p>
            ))}
          </div>
        </Columns>
        <SiteLink>View <a href={Link}>{Name} ></a></SiteLink>
      </Content>
      <Banner>
        <Img fluid={Main_Image.localFiles[1].childImageSharp.fluid} />
      </Banner>
      <Button link="/portfolio" text="Portfolio" />
    </Layout>
  )
}

const SiteLink = styled.p`
  padding-top: 40px;
  font-weight: bold;
  text-align: center;
`

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    flex: 1;
    padding: 10px;
    min-width: 200px;
  }
  p {
    margin: 0;
  }
`

const Divider = styled.div`
  width: 100%;
  border-top: solid 1px ${colours.blue};
  height: 1px;
  margin: 40px 0;
`

const Content = styled.div`
  max-width: 800px;
  margin: 20px auto 80px;
  padding: 20px;
`

const Banner = styled.div`
  .gatsby-image-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
  width: 100%;
  background: ${colours.navy};
`

interface Props {
  data: {
    airtable: {
      data: {
        Name: string;
        Link: string;
        Description: {
          childMarkdownRemark: {
            html: string
          }
        }
        Services: string;
        Features: string;
        Main_Image: any;
      }
    }
  }
}

export default PortfolioPage

export const portfolioQuery = graphql`
  query($id: String!) {
    airtable(table: { eq: "items" }, id: { eq: $id }) {
      data {
        Name
        Description {
          childMarkdownRemark {
            html
          }
        }
        Link
        Services
        Features
        Main_Image {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
