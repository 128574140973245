import { Link } from "gatsby"
import * as React from "react"
import styled from "styled-components"
import { colours } from "../global/colours"

interface Props {
  link: string
  text: string
}

const StyledButton = styled(Link)`
  background: ${colours.peach};
  color: ${colours.white};
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    90deg,
    ${colours.peach} 0%,
    ${colours.darkPeach} 100%
  );
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  width: max-content;
  padding: 20px;
  margin: 80px auto;
  font-weight: bold;
  box-shadow: 2px 2px 6px #eee;
  transition: border-radius 1.2s;
  &:hover {
    border-radius: 68% 32% 70% 30% / 72% 76% 24% 28%;
  }
`

export const Button = ({ link, text }: Props) => {
  return <StyledButton to={link}>{text}</StyledButton>
}
